<template>
  <div
    class="mats-listing__wrapper color-listing__wrapper"
    style="margin-top: -8px;width:45%"
  >
    <div style="display:flex;justify-content: space-between;">
      <button
        v-b-modal.assign-windows-to-order
        class="button"
        @click="editTeams"
      >
        {{ $t("AssignTeam") }}
      </button>
      <button
        v-if="orderIds !== null && getOrderDetailsByOrderId.length == 0"
        v-b-modal.add-new-object
        class="button"
        @click="callPayment"
      >
        {{ $t("AddObject") }}
      </button>
    </div>
    <div style="width: 100%; padding-top: 0px;">
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                :checked="selectAll"
                @change="toggleSelectAll"
              >
            </th>
            <th>
              {{ $t('houseName') }}
            </th>
            <th>
              {{ $t("floorName") }}
            </th>
            <th>
              {{ $t("roomName") }}
            </th>
            <th>
              {{ $t("objectName") }}
            </th>
            <th>
              {{ $t("AssignTeam") }}
            </th>
            <!-- <th>
              {{ $t("Actions") }}
            </th> -->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item,index) in formattedOrderDetails"
            :key="index"
          >
            <td>
              <input
                type="checkbox"
                :value="item.windowId"
                :checked="isItemChecked(item)"
                @change="event => toggleOrderItem(event, index)"
              >
            </td>
            <td>
              {{ item.houseName }}
            </td>
            <td>
              {{ item.floorName }}
            </td>
            <td>
              {{ item.roomName }}
            </td>
            <td>
              {{ item.objectName }}
            </td>
            <td
              v-b-modal.assign-team-order
              @click="editTeam(item.windowId)"
            >
              <button
                class="editButton"
              >
                {{ $t('Assign') }}
              </button>
            </td>
            <!-- <td
              v-b-toggle.edit-order-item
              @click="editWindow(item.windowId)"
            >
              <button
                class="editButton"
              >
                {{ $t('Edit') }}
              </button>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <EditOrderItemToggle
      :window-id="windowId"
      :order-ids="orderIds"
      @onCancelToggle="onCancelToggle"
      @editObject="editWindowObject"
    />
    <AssignTeamToOrder
      :window-id="windowId"
      :order-id="orderId"
      @assignTeam="assignTeamToWindow"
    />
    <AssignWindowsToOrder
      :order-item-ids="orderItemIds"
      @assignTeams="assignTeamToWindows"
    />
    <AddNewObjectOrder
      :order-ids="orderIds"
    />
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddNewObjectOrder from '@/components/orders/modals/AddNewObjectOrder.vue'
import EditOrderItemToggle from '@/components/orders/modals/EditOrderItemToggle.vue'
import AssignTeamToOrder from '@/components/orders/modals/AssignTeamToOrder.vue'
import AssignWindowsToOrder from '@/components/orders/modals/AssignWindowsToOrder.vue'
import { client } from '../../domainConfig'


export default {
  components: {
    EditOrderItemToggle,
    AssignWindowsToOrder,
    AssignTeamToOrder,
    AddNewObjectOrder,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['orderId', 'createOrder', 'orderIds'],
  data() {
    return {
      isSidebarOpen: false,
      client: client.clientName,
      windowId: null,
      orderItemIds: [],
      selectAll: false,
    };
  },
  computed: {
    ...mapGetters(['getCreatedOrdersByOrderNumber', 'getOrderDetailsByOrderId', 'getCurrentPageForCreatedOrders', 'getTotalItemsForOrderItems', 'getCurrentPageForOrderItems', 'getIsLoading', 'getLoggedInUser']),
    formattedOrderDetails() {
      return this.getOrderDetailsByOrderId.map((detail) => {
        const parts = detail.articleName.split('/');

        return {
          windowId: detail.windowId,
          houseName: parts[0] || 'Shtepia',
          floorName: parts[1] || 'Kati',
          roomName: parts[2] || 'Dhoma',
          objectName: parts[3] || 'Objekti(Dritare/Tepison)',
        };
      });
    },
  },
  watch: {

  },
  methods: {
    ...mapActions(['edit_OrderItem', 'loadPaidAmountByOrderid', 'resetDetailsOrderId', 'orderDetailsOrderId', 'assignWindowTo', 'assignWindowsTo', 'dropdown_TeamNames', 'activeInactiveUsers', 'editWindowDetails', 'loadWindowDetails', 'teamNames', 'loadCreatedOrdersByOrderNumber', 'assignOrderItemToTeamLeader', 'loadCreatedOreders', 'changeLoadingtoTrue', 'resetCreatedOrdersByOrderNumber', 'getTeamNamesPagination', 'sell_productInStock', 'get_edit_OrderItem', 'edit_OrderItem_MaterialVariants']),
    callPayment() {
      this.loadPaidAmountByOrderid({
        orderId: this.orderIds,
      })
    },
    toggleOrderItem(event, index) {
      const item = this.formattedOrderDetails[index];
      const itemObject = {
        windowId: item.windowId,
        objectName: `${item.houseName}-${item.floorName}-${item.roomName}-${item.objectName}`,
      };

      if (event.target.checked) {
        this.orderItemIds.push(itemObject);
      } else {
        const itemIndex = this.orderItemIds.findIndex(
          (i) => { return i.windowId === item.windowId && i.objectName === item.objectName },
        );
        if (itemIndex !== -1) {
          this.orderItemIds.splice(itemIndex, 1);
        }
      }
      this.selectAll = this.orderItemIds.length === this.formattedOrderDetails.length;
    },
    toggleSelectAll(event) {
      this.selectAll = event.target.checked;

      if (this.selectAll) {
        this.orderItemIds = this.formattedOrderDetails.map((item) => {
          return {
            windowId: item.windowId,
            objectName: `${item.houseName}-${item.floorName}-${item.roomName}-${item.objectName}`,
          }
        });
      } else {
        this.orderItemIds = [];
      }
    },
    isItemChecked(item) {
      return this.orderItemIds.some(
        (i) => { return i.windowId === item.windowId },
      );
    },
    editWindowObject(value) {
      this.editWindowDetails({
        object: value,
        successCallback: () => {

        },
      })
    },
    assignTeamToWindow(value) {
      this.assignWindowTo({
        object: value,
        successCallback: () => {
          this.resetDetailsOrderId()
          this.loadCreatedOreders({
            pageNumber: 1,
            pageSize: 15,
            orderNumber: null,
            clientId: null,
          })
          this.orderItemIds = []
          this.selectAll = false
          this.$emit('className')
        },
      })
    },
    assignTeamToWindows(value) {
      this.assignWindowsTo({
        object: value.windowItemIds,
        teamId: value.teamNameId,
        orderNumber: this.orderId,
        successCallback: () => {
          this.resetDetailsOrderId()
          this.loadCreatedOreders({
            pageNumber: 1,
            pageSize: 15,
            orderNumber: null,
            clientId: null,
          })
          this.orderItemIds = []
          this.selectAll = false
          this.$emit('className')
        },
      })
    },
    editTeam(value) {
      this.windowId = value
      this.activeInactiveUsers({
        pageNumber: 1,
        pageSize: 50,
        status: true,
        userId: null,
      });
      this.dropdown_TeamNames(30)
    },
    editTeams() {
      this.activeInactiveUsers({
        pageNumber: 1,
        pageSize: 50,
        status: true,
        userId: null,
      });
      this.dropdown_TeamNames(30)
    },
    editWindow(value) {
      this.isSidebarOpen = true
      this.windowId = value
      this.loadWindowDetails({
        windowId: value,
        orderId: this.orderIds,
      })
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
  },
};
</script>

<style scoped lang="scss">

.team_table td {
  padding: 11px 9px !important;
}
.color-listing__wrapper input {
    width: 100% !important;
}
.team_table th {
  // text-align: center;
}

// .editButton {
//   width: fit-content !important;
//   height: 30px;
// }
.color-listing__wrapper {
  margin-left: 20px;
  .mats-listing {
    margin-top: 0;
  }
  .table__search-input {
    visibility: hidden;
  }
  input {
    width: 100px;
  }
}
.button {
  margin-bottom: 15px !important;
}

.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

tr:last-child {
  border-bottom: none !important;
}

.editButton {
  width: 104px !important;
}

@media screen and (max-width: 1100px) {
  .color-listing__wrapper {
    margin-left: 0px;
  }
}
</style>
