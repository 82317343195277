<template>
  <b-modal
    id="edit-new-object"
    ref="modal"
    header-class="headerModal"
    centered
    :title="`${$t('AddObject')}: ${nameOfOrder}`"
    hide-footer
    size="xl"
    @close="onCancel"
  >
    <div class="houseDetail">
      <button
        type="button"
        variant="none"
        class="buttonSubmit"
        style="width: 240px; height: 40px;margin:10px 0 "
        @click="addHouse"
      >
        {{ $t('AddHouse') }}
      </button>
      <section
        v-for="(item, index) in createHouseItemRequestDtos"
        :key="index"
        class="sectionClass"
      >
        <div class="homeEdit">
          <div class="homeName">
            <img src="../../../assets/images/houseImage.svg">
            <b-form-input
              v-if="item.editable"
              v-model="item.name"
              type="text"
              class="editInput"
              @blur="saveHouseName(index)"
              @keydown.enter="saveHouseName(index)"
            />
            <span v-else>{{ item.name }}</span>
          </div>
          <div class="iconsClass">
            <b-icon-pencil
              v-if="!item.editable"
              style="cursor:pointer"
              @click="toggleEditHouseName(index)"
            />
            <b-icon-check-circle
              v-if="item.editable"
              style="cursor:pointer"
              @click="toggleEditHouseName(index)"
            />
            <b-icon-trash
              style="cursor:pointer;"
              @click="removeHouse(index)"
            />
            <button
              v-if="item.createFloorRequestDtos.length == 0"
              class="buttonNotaSmall"
              type="button"
              @click="addFloor(index)"
            >
              Shto Katin e ri
            </button>
            <b-button
              variant="link"
              class="collapse-button"
              @click="toggleCollapse(index)"
            >
              <b-icon-chevron-down v-if="!isCollapsed(index)" />
              <b-icon-chevron-up v-if="isCollapsed(index)" />
            </b-button>
          </div>
        </div>
        <p
          v-if="item.createFloorRequestDtos.length == 0 && isButtonSubmited "
          class="validationClass"
        >
          {{ $t('Please select a floor') }}
        </p>
        <b-collapse :visible="!isCollapsed(index)">
          <hr
            v-if="item.createFloorRequestDtos.length > 0"
            class="hrClass"
          >
          <div :class="isCollapsedFloor(index) ? 'buttonOnly' : 'buttonAndCollapse'">
            <p
              v-if="isCollapsedFloor(index)"
              style="margin: 0px;font-weight: 600"
            >
              Floor closed
            </p>
            <b-collapse
              :visible="!isCollapsedFloor(index)"
            >
              <div class="allClases">
                <div
                  v-for="(floor, floorIndex) in item.createFloorRequestDtos"
                  :key="floorIndex"
                  class="floorWrapper"
                >
                  <button
                    class="objectsClass"
                    type="button"
                    :class="{ 'selectedFloor': selectedFloorIndex === floorIndex }"
                    @click="selectFloorToAddRoom(index, floorIndex)"
                  >
                    <span class="iconsFloor">
                      <b-icon-pencil
                        v-if="!floor.editable"
                        @click="toggleEditFloorName(index, floorIndex)"
                      />
                      <b-icon-check-circle
                        v-if="floor.editable"
                        @click="toggleEditFloorName(index, floorIndex)"
                      />
                      <b-icon-trash @click="removeFloor(index, floorIndex)" />
                    </span>
                    <span class="nameFloor">
                      <img
                        src="../../../assets/images/HomeImage.svg"
                        style="height: 60px;"
                      >
                      <b-form-input
                        v-if="floor.editable"
                        v-model="floor.name"
                        type="text"
                        class="editInput"
                        @blur="saveFloorName(index, floorIndex)"
                        @keydown.enter="saveFloorName(index, floorIndex)"
                      />
                      <span v-else>{{ floor.name }}</span>
                    </span>
                  </button>
                </div>
                <div v-if="item.createFloorRequestDtos.length > 0">
                  <button
                    class="buttonNota"
                    type="button"
                    @click="addFloor(index)"
                  >
                    Shto Katin e ri
                  </button>
                </div>

              </div>
            </b-collapse>
            <div
              v-if="item.createFloorRequestDtos.length > 0"
              class="buttonsCollapsed"
            >
              <b-button
                variant="link"
                class="collapse-button"
                @click="toggleCollapseFloor(index)"
              >
                <b-icon-chevron-down v-if="!isCollapsedFloor(index)" />
                <b-icon-chevron-up v-if="isCollapsedFloor(index)" />
              </b-button>
            </div>
          </div>
          <hr
            v-if="item.createFloorRequestDtos.length > 0"
            class="hrClass"
          >
          <div :class="isCollapsedRoom(index) ? 'buttonOnly' : 'buttonAndCollapse'">
            <p
              v-if="isCollapsedRoom(index)"
              style="margin: 0px;font-weight: 600"
            >
              Room closed
            </p>
            <b-collapse
              :visible="!isCollapsedRoom(index)"
            >
              <div class="allClases">
                <div
                  v-for="(room, roomIndex) in item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos"
                  :key="roomIndex"
                  class="floorWrapper"
                >
                  <button
                    class="objectsClass"
                    type="button"
                    :class="{ 'selectedFloor': selectedRoomIndex === roomIndex }"
                    @click="selectRoomToAddObject(index, selectedFloorIndex, roomIndex)"
                  >
                    <span class="iconsFloor">
                      <b-icon-pencil
                        v-if="!room.editable"
                        @click="toggleEditRoomName(index, selectedFloorIndex, roomIndex)"
                      />
                      <b-icon-check-circle
                        v-if="room.editable"
                        @click="toggleEditRoomName(index, selectedFloorIndex, roomIndex)"
                      />
                      <b-icon-trash @click="removeRoom(index, selectedFloorIndex, roomIndex)" />
                    </span>
                    <span class="nameFloor">
                      <img
                        src="../../../assets/images/RoomImage.svg"
                        style="height: 60px;"
                      >
                      <b-form-input
                        v-if="room.editable"
                        v-model="room.name"
                        type="text"
                        class="editInput"
                        @blur="saveRoomName(index, selectedFloorIndex, roomIndex)"
                        @keydown.enter="saveRoomName(index, selectedFloorIndex, roomIndex)"
                      />
                      <span v-else>{{ room.name }}</span>
                    </span>
                  </button>
                </div>
                <div
                  v-if="item.createFloorRequestDtos.length > 0"
                >
                  <button
                    class="buttonNota"
                    type="button"
                    @click="addRoom(index, selectedFloorIndex)"
                  >
                    Shto Dhomen e re
                  </button>

                </div>
              </div>
            </b-collapse>
            <div
              v-if="item.createFloorRequestDtos.length > 0"
              class="buttonsCollapsed"
            >
              <b-button
                variant="link"
                class="collapse-button"
                @click="toggleCollapseRoom(index)"
              >
                <b-icon-chevron-down v-if="!isCollapsedRoom(index)" />
                <b-icon-chevron-up v-if="isCollapsedRoom(index)" />
              </b-button>
            </div>
          </div>
          <p
            v-if="item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos.length == 0 && isButtonSubmited "
            class="validationClass"
          >
            {{ $t('Please select a room') }}
          </p>
          <hr
            v-if="item.createFloorRequestDtos.length > 0"
            class="hrClass"
          >
          <div :class="isCollapsedObject(index) ? 'buttonOnly' : 'buttonAndCollapse'">
            <p
              v-if="isCollapsedObject(index)"
              style="margin: 0px;font-weight: 600"
            >
              Object closed
            </p>
            <b-collapse
              :visible="!isCollapsedObject(index)"
            >
              <div class="allClases">
                <div
                  v-for="(window, windowIndex) in item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos[selectedRoomIndex]?.createWindowRequestDtos"
                  :key="windowIndex"
                  class="floorWrapper"
                >
                  <button
                    class="objectsClass"
                    type="button"
                    :class="{ 'selectedFloor': selectedWindowIndex === windowIndex }"
                    @click="selectWindowToObject(index, selectedFloorIndex, selectedRoomIndex, windowIndex)"
                  >
                    <span class="iconsFloor">
                      <b-icon-pencil
                        v-if="!window.editable"
                        @click="toggleEditWindowName(index, selectedFloorIndex, selectedRoomIndex, windowIndex, window)"
                      />
                      <b-icon-check-circle
                        v-if="window.editable"
                        @click="toggleEditWindowName(index, selectedFloorIndex, selectedRoomIndex, windowIndex, window)"
                      />
                      <b-icon-trash @click="removeWindow(index, selectedFloorIndex, selectedRoomIndex, windowIndex)" />
                    </span>
                    <span class="nameFloor">
                      <img
                        src="../../../assets/images/ObjectImage.svg"
                        style="height: 60px;"
                      >
                      <b-form-input
                        v-if="window.editable"
                        v-model="window.name"
                        type="text"
                        class="editInput"
                        @blur="saveWindowName(index, selectedFloorIndex, selectedRoomIndex, windowIndex, window)"
                        @keydown.enter="saveWindowName(index, selectedFloorIndex, selectedRoomIndex, windowIndex, window)"
                      />
                      <span v-else>{{ window.name }}</span>
                    </span>
                  </button>
                </div>
                <button
                  v-if="item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos.length > 0"
                  class="buttonNota"
                  type="button"
                  @click="addWindow(index)"
                >
                  Shto Objektin e ri
                </button>
              </div>
            </b-collapse>
            <div
              v-if="item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos.length > 0"
              class="buttonsCollapsed"
            >
              <b-button
                variant="link"
                class="collapse-button"
                @click="toggleCollapseObject(index)"
              >
                <b-icon-chevron-down v-if="!isCollapsedObject(index)" />
                <b-icon-chevron-up v-if="isCollapsedObject(index)" />
              </b-button>
            </div>
          </div>
          <p
            v-if="item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos[selectedRoomIndex]?.createWindowRequestDtos.length == 0 && isButtonSubmited "
            class="validationClass"
          >
            {{ $t('Please select a object') }}
          </p>
          <div v-if="selectedWindowIndex !== null">
            <div :class="isCollapsedObjectMaterials(index) ? 'buttonOnly' : 'buttonAndCollapse'">
              <p
                v-if="isCollapsedObjectMaterials(index)"
                style="margin: 5px 0px 0px 0px;font-weight: 600"
              >
                Materials closed
              </p>
              <b-collapse
                :visible="!isCollapsedObjectMaterials(index)"
                style="width:100%"
              >
                <div v-if="item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos[selectedRoomIndex]?.createWindowRequestDtos && item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos[selectedRoomIndex]?.createWindowRequestDtos.length > 0">

                  <div
                    v-for="(window, windowIndex) in item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos[selectedRoomIndex]?.createWindowRequestDtos"
                    :key="windowIndex"
                  >
                    <div
                      v-if="windowIndex === selectedWindowIndex"
                      class="allMaterialsDetails"
                    >
                      <label style="font-size: 20px;font-weight:500">{{ windowIndex + 1 }}.{{ $t('WorkingNow') }} : {{ window.name }} </label>
                      <div style="display:flex;">
                        <div class="ordersService">
                          <b-form-checkbox
                            v-model="window.orderOtherService.pattern"
                          />
                          <label style="font-size: 25px">{{ $t('InPattern') }} </label>
                        </div>
                        <div class="ordersService">
                          <b-form-checkbox
                            v-model="window.orderOtherService.sewing"
                          />
                          <label style="font-size: 25px">{{ $t('Sewing') }} </label>
                        </div>
                        <div class="ordersService">
                          <b-form-checkbox
                            v-model="window.orderOtherService.transport"
                          />
                          <label style="font-size: 25px">{{ $t('Transport') }} </label>
                        </div>
                        <div class="ordersService">
                          <b-form-checkbox
                            v-model="window.orderOtherService.montage"
                            @input="checkMontage(window.orderOtherService.montage, window)"
                          />
                          <label style="font-size: 25px">{{ $t('Montage') }} </label>
                        </div>
                      </div>
                      <div style="display: flex;gap:15px">
                        <b-form-group
                          :label="$t('HingType')"
                          style="width: 20%;"
                        >
                          <vue-select
                            v-model="window.hingType"
                            :options="hingeTypes"
                            label="name"
                            :reduce="(e) => e.value"
                            :placeholder="$t('Select')"
                            :multiple="true"
                            @input="updateDecorative(index, window, $event)"
                          />
                        </b-form-group>
                        <b-form-group
                          v-if="window.orderOtherService.montage == true"
                          :label="$t('MontageStart')"
                        >
                          <date-picker
                            v-model="window.montageStart"
                            type="datetime"
                            :placeholder="$t('SelectD')"
                            format="YYYY-MM-DD HH:mm"
                          />
                        </b-form-group>
                        <b-form-group
                          v-if="window.orderOtherService.montage == true"
                          :label="$t('MontageEnd')"
                        >
                          <date-picker
                            v-model="window.montageEnd"
                            type="datetime"
                            :placeholder="$t('SelectD')"
                            format="YYYY-MM-DD HH:mm"
                          />
                        </b-form-group>
                      </div>
                      <div style="display: flex;gap:15px">
                        <b-form-group
                          :label="$t('Width')"
                        >
                          <b-form-input
                            v-model.number="window.width"
                            :placeholder="$t('0')"
                            @input="updateMaterialLengthOrQuantity(window); updateWindowDecorativeWidth(window)"
                          />
                          <p
                            v-if="window.width < 0.0001 && isButtonSubmited "
                            class="validationClass"
                          >
                            {{ $t('TheValue') }}
                          </p>
                        </b-form-group>
                        <b-form-group
                          :label="$t('Height')"
                        >
                          <b-form-input
                            v-model.number="window.height"
                            :placeholder="$t('0')"
                            @input="updateWindowDecorativeHeight(window)"
                          />
                          <p
                            v-if="window.height < 0.0001 && isButtonSubmited "
                            class="validationClass"
                          >
                            {{ $t('TheValue') }}
                          </p>
                        </b-form-group>
                        <b-form-group
                          :label="$t('SewingType')"
                          style="width: 30%;"
                        >
                          <vue-select
                            v-model="window.sewingType"
                            :options="sewingTypes"
                            label="name"
                            :reduce="(e) => e.value"
                            :placeholder="$t('Select')"
                            @input="handleSewingMethod(window)"
                          />
                          <p
                            v-if="window.sewingType == null && isButtonSubmited "
                            class="validationClass"
                          >
                            {{ $t('Please select a sewing type') }}
                          </p>
                        </b-form-group>
                      </div>
                      <div
                        v-for="(material, materialIndex) in window.materialVariantInStoreDtos"
                        :key="materialIndex"
                        class="materialsClass"
                      >
                        <div style="display:flex;justify-content: space-between;padding-right: 80px">
                          <label> {{ materialIndex + 1 }}.{{ $t('MeasurementType') }} <span style="font-weight:600"> {{ material.parameter ? `: ${material.parameter}`: null }} </span></label>
                          <b-icon-trash
                            style="cursor:pointer;"
                            @click="removeMaterial(index, materialIndex)"
                          />
                        </div>
                        <b-form-group>
                          <b-form-radio-group
                            v-model="material.measurementType"
                            style="display:flex;gap: 10px;width:90%"
                            :disabled="material.parameter === 'Shirit' ? true : false"
                            @change="resetMaterialName(material, window)"
                          >
                            <b-form-radio
                              class="form-radio"
                              value="Meter"
                            >
                              <p>{{ $t('Meter') }} </p>
                            </b-form-radio>
                            <b-form-radio
                              class="form-radio"
                              value="Meter_Square"
                            >
                              <p>{{ $t('Meter_Square') }} </p>
                            </b-form-radio>
                            <b-form-radio
                              class="form-radio"
                              value="Part"
                            >
                              <p>{{ $t('Part') }} </p>
                            </b-form-radio>
                            <b-form-radio
                              class="form-radio"
                              value="Verse"
                            >
                              <p>{{ $t('Verse') }} </p>
                            </b-form-radio>
                            <b-form-radio
                              class="form-radio"
                              value="Package"
                            >
                              <p>{{ $t('Package') }} </p>
                            </b-form-radio>
                            <b-form-radio
                              class="form-radio"
                              value="Pair"
                            >
                              <p>{{ $t('Pair') }} </p>
                            </b-form-radio>
                            <b-form-radio
                              class="form-radio"
                              value="Set"
                            >
                              <p>{{ $t('Set') }} </p>
                            </b-form-radio>
                          </b-form-radio-group>
                        </b-form-group>
                        <div style="display:flex;gap:10px;width:100%">
                          <b-form-group
                            :label="$t('MaterialVariant')"
                            style="width:25%"
                          >
                            <vue-select
                              v-model="material.name"
                              :placeholder="$t('Description')"
                              label="materialVariantName"
                              :options="material.parameter === 'Shirit' ? [] : getMaterialOptions(material.measurementType)"
                              :clearable="material.parameter === 'Shirit' ? false : true"
                              @search="searchMaterial(material.measurementType, $event)"
                              @input="updateMaterialVariantId(material.name, material)"
                            >
                              <li
                                slot="list-footer"
                                class="pagination"
                                style="display: flex; width: 100%; justify-content: center;"
                              >
                                <div
                                  v-if="getMaterialVariantColorNameLike.length > 1"
                                  style="display:flex;gap:5px; padding-top: 3px;width:98%"
                                >
                                  <button
                                    class="buttonCancel"
                                    type="button"
                                    :disabled="pageNumberFilter == 1"
                                    @click="previousPage(material.measurementType, searchQuery)"
                                  >
                                    {{ $t('Previous') }}
                                  </button>
                                  <button
                                    class="buttonSubmit"
                                    type="button"
                                    @click="nextPage(material.measurementType, searchQuery)"
                                  >
                                    {{ $t('Next') }}
                                  </button>
                                </div>
                              </li>
                            </vue-select>
                            <p
                              v-if="(material.name == null || material.name == '' )&& isButtonSubmited "
                              class="validationClass"
                            >
                              {{ $t('Please Select a material variant') }}
                            </p>
                          </b-form-group>
                          <b-form-group
                            :label="$t(material.measurementType)"
                          >
                            <b-input
                              v-model.number="material.quantity"
                              required
                              type="number"
                              @input="calculateTotalPerMeter(material.pricePerMeter, material.quantity, material)"
                            />
                            <p
                              v-if="material.quantity < 0.0001 && isButtonSubmited "
                              class="validationClass"
                            >
                              {{ $t('TheValue') }}
                            </p>
                          </b-form-group>
                          <b-form-group
                            :label="$t('pricePerMeter')"
                          >
                            <b-input
                              v-model.number="material.pricePerMeter"
                              required
                              disabled
                              type="number"
                              @input="calculateTotalPerMeter(material.pricePerMeter, material.length || material.quantity, material)"
                            />
                          </b-form-group>
                          <b-form-group
                            :label="$t('PriceDiscount')"
                          >
                            <b-input
                              v-model.number="material.pricePerMeterWithDiscount"
                              required
                              type="number"
                              @input="calculateTotalPerMeter(material.pricePerMeterWithDiscount, material.length || material.quantity, material)"
                            />
                          </b-form-group>
                          <b-form-group
                            :label="$t('TotalPerMeter')"
                          >
                            <b-input
                              v-model.number="material.totalPerMeter"
                              disabled
                              type="number"
                            />
                          </b-form-group>
                        </div>
                      </div>
                      <button
                        class="buttonNotaSmall"
                        type="button"
                        @click="addMaterialObject(index, window)"
                      >
                        Shto Material
                      </button>
                      <p
                        v-if="window.materialVariantInStoreDtos.length == 0 && isButtonSubmited"
                        class="validationClass"
                      >
                        {{ $t('AddMaterial') }}
                      </p>
                      <div
                        v-for="(windowDecorative, windowDecorativeIndex) in window.createWindowDecorativeRequestDtos"
                        :key="windowDecorativeIndex"
                        class="decorativeWindow"
                      >
                        <span style="color:lightcoral;font-size: 20px"> {{ windowDecorative.name }} </span>
                        <div style="display:flex;">
                          <div class="ordersService">
                            <b-form-checkbox
                              v-model="windowDecorative.orderOtherService.pattern"
                            />
                            <label style="font-size: 25px">{{ $t('InPattern') }} </label>
                          </div>
                          <div class="ordersService">
                            <b-form-checkbox
                              v-model="windowDecorative.orderOtherService.sewing"
                            />
                            <label style="font-size: 25px">{{ $t('Sewing') }} </label>
                          </div>
                          <div class="ordersService">
                            <b-form-checkbox
                              v-model="windowDecorative.orderOtherService.transport"
                            />
                            <label style="font-size: 25px">{{ $t('Transport') }} </label>
                          </div>
                          <div class="ordersService">
                            <b-form-checkbox
                              v-model="windowDecorative.orderOtherService.montage"
                            />
                            <label style="font-size: 25px">{{ $t('Montage') }} </label>
                          </div>
                        </div>
                        <div style="display: flex;gap:15px">
                          <b-form-group
                            :label="$t('MontageStart')"
                          >
                            <date-picker
                              v-model="windowDecorative.montageStart"
                              type="datetime"
                              :placeholder="$t('SelectD')"
                              format="YYYY-MM-DD HH:mm"
                            />
                          </b-form-group>
                          <b-form-group
                            :label="$t('MontageEnd')"
                          >
                            <date-picker
                              v-model="windowDecorative.montageEnd"
                              type="datetime"
                              :placeholder="$t('SelectD')"
                              format="YYYY-MM-DD HH:mm"
                            />
                          </b-form-group>
                        </div>
                        <div style="display: flex;gap:15px">
                          <b-form-group
                            :label="$t('Width')"
                          >
                            <b-form-input
                              v-model.number="windowDecorative.width"
                              :placeholder="$t('0')"
                              @input="updateMaterialLengthOrQuantity(windowDecorative)"
                            />
                            <p
                              v-if="windowDecorative.width < 0.0001"
                              class="validationClass"
                            >
                              {{ $t('TheValue') }}
                            </p>
                          </b-form-group>
                          <b-form-group
                            :label="$t('Height')"
                          >
                            <b-form-input
                              v-model.number="windowDecorative.height"
                              :placeholder="$t('0')"
                            />
                            <p
                              v-if="windowDecorative.height < 0.0001"
                              class="validationClass"
                            >
                              {{ $t('TheValue') }}
                            </p>
                          </b-form-group>
                          <b-form-group
                            :label="$t('SewingType')"
                            style="width: 30%;"
                          >
                            <vue-select
                              v-model="windowDecorative.sewingType"
                              :options="sewingTypes"
                              label="name"
                              :reduce="(e) => e.value"
                              :placeholder="$t('Select')"
                              @input="handleSewingMethod(windowDecorative)"
                            />
                            <p
                              v-if="windowDecorative.sewingType == null"
                              class="validationClass"
                            >
                              {{ $t('Please select a sewing type') }}
                            </p>
                          </b-form-group>
                        </div>
                        <div
                          v-for="(materialDecorative, materialIndexDecorative) in windowDecorative.materialVariantInStoreDtos"
                          :key="materialIndexDecorative"
                          class="materialsClass"
                        >
                          <div style="display:flex;justify-content: space-between;padding-right: 10px">
                            <label> {{ materialIndexDecorative + 1 }}.{{ $t('MeasurementType') }} <span style="font-weight:600"> {{ materialDecorative.parameter ? `: ${materialDecorative.parameter}`: null }} </span></label>
                            <b-icon-trash
                              style="cursor:pointer"
                              @click="removeMaterialDecorative(index, windowDecorativeIndex, materialIndexDecorative)"
                            />
                          </div>
                          <b-form-group>
                            <b-form-radio-group
                              v-model="materialDecorative.measurementType"
                              :disabled="materialDecorative.parameter === 'Shirit' ? true : false"
                              style="display:flex;gap: 10px;width:90%"
                              @change="resetMaterialName(materialDecorative, window)"
                            >
                              <b-form-radio
                                class="form-radio"
                                value="Meter"
                              >
                                <p>{{ $t('Meter') }} </p>
                              </b-form-radio>
                              <b-form-radio
                                class="form-radio"
                                value="Meter_Square"
                              >
                                <p>{{ $t('Meter_Square') }} </p>
                              </b-form-radio>
                              <b-form-radio
                                class="form-radio"
                                value="Part"
                              >
                                <p>{{ $t('Part') }} </p>
                              </b-form-radio>
                              <b-form-radio
                                class="form-radio"
                                value="Verse"
                              >
                                <p>{{ $t('Verse') }} </p>
                              </b-form-radio>
                              <b-form-radio
                                class="form-radio"
                                value="Package"
                              >
                                <p>{{ $t('Package') }} </p>
                              </b-form-radio>
                              <b-form-radio
                                class="form-radio"
                                value="Pair"
                              >
                                <p>{{ $t('Pair') }} </p>
                              </b-form-radio>
                              <b-form-radio
                                class="form-radio"
                                value="Set"
                              >
                                <p>{{ $t('Set') }} </p>
                              </b-form-radio>
                            </b-form-radio-group>
                          </b-form-group>
                          <div style="display:flex;gap:10px;width:100%">
                            <b-form-group
                              :label="$t('MaterialVariant')"
                              style="width:25%"
                            >
                              <vue-select
                                v-model="materialDecorative.name"
                                :placeholder="$t('Description')"
                                label="materialVariantName"
                                :options="materialDecorative.parameter === 'Shirit' ? [] : getMaterialOptions(materialDecorative.measurementType)"
                                :clearable="materialDecorative.parameter === 'Shirit' ? false : true"
                                @input="updateMaterialVariantId(materialDecorative.name, materialDecorative)"
                                @search="searchMaterial(materialDecorative.measurementType, $event)"
                              >
                                <li
                                  slot="list-footer"
                                  class="pagination"
                                  style="display: flex; width: 100%; justify-content: center;"
                                >
                                  <div
                                    v-if="getMaterialVariantColorNameLike.length > 1"
                                    style="display:flex;gap:5px; padding-top: 3px;width:98%"
                                  >
                                    <button
                                      class="buttonCancel"
                                      type="button"
                                      :disabled="pageNumberFilter == 1"
                                      @click="previousPage(materialDecorative.measurementType, searchQuery)"
                                    >
                                      {{ $t('Previous') }}
                                    </button>
                                    <button
                                      class="buttonSubmit"
                                      type="button"
                                      @click="nextPage(materialDecorative.measurementType, searchQuery)"
                                    >
                                      {{ $t('Next') }}
                                    </button>
                                  </div>
                                </li>
                              </vue-select>
                              <p
                                v-if="(materialDecorative.name == null || materialDecorative.name == '' )"
                                class="validationClass"
                              >
                                {{ $t('Please Select a material variant') }}
                              </p>
                            </b-form-group>
                            <b-form-group
                              :label="$t(materialDecorative.measurementType)"
                            >
                              <b-input
                                v-model.number="materialDecorative.quantity"
                                required
                                type="number"
                                @input="calculateTotalPerMeter(materialDecorative.pricePerMeter, materialDecorative.quantity, materialDecorative)"
                              />
                              <p
                                v-if="materialDecorative.quantity < 0.0001"
                                class="validationClass"
                              >
                                {{ $t('TheValue') }}
                              </p>
                            </b-form-group>
                            <b-form-group
                              :label="$t('pricePerMeter')"
                            >
                              <b-input
                                v-model.number="materialDecorative.pricePerMeter"
                                required
                                disabled
                                type="number"
                                @input="calculateTotalPerMeter(materialDecorative.pricePerMeter, materialDecorative.length || materialDecorative.quantity, materialDecorative)"
                              />
                            </b-form-group>
                            <b-form-group
                              :label="$t('PriceDiscount')"
                            >
                              <b-input
                                v-model.number="materialDecorative.pricePerMeterWithDiscount"
                                required
                                type="number"
                                @input="calculateTotalPerMeter(materialDecorative.pricePerMeterWithDiscount, materialDecorative.length || materialDecorative.quantity, materialDecorative)"
                              />
                            </b-form-group>
                            <b-form-group
                              :label="$t('TotalPerMeter')"
                            >
                              <b-input
                                v-model.number="materialDecorative.totalPerMeter"
                                disabled
                                type="number"
                              />
                            </b-form-group>
                          </div>
                        </div>
                        <div style="margin-top: 10px">
                          <button
                            v-if="window.createWindowDecorativeRequestDtos.length > 0"
                            class="buttonNotaSmall"
                            type="button"
                            @click="addMaterialObjectDecorative(index, window, windowDecorativeIndex)"
                          >
                            Shto Material
                          </button>
                        </div>
                        <p
                          v-if="windowDecorative.materialVariantInStoreDtos.length == 0"
                          class="validationClass"
                        >
                          {{ $t('AddMaterial') }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
              <div
                v-if="item.createFloorRequestDtos[selectedFloorIndex]?.createRoomRequestDtos[selectedRoomIndex]?.createWindowRequestDtos.length > 0"
                class="buttonsCollapsed"
              >
                <b-button
                  variant="link"
                  class="collapse-button"
                  @click="toggleCollapseObjectMaterials(index)"
                >
                  <b-icon-chevron-down v-if="!isCollapsedObjectMaterials(index)" />
                  <b-icon-chevron-up v-if="isCollapsedObjectMaterials(index)" />
                </b-button>
              </div>
            </div>
          </div>
        </b-collapse>
      </section>
      <section
        class="custom-modal__wrapper"
        style="margin-bottom:10px;margin-top:10px"
      >
        <div style="display:flex;margin-bottom: 10px">
          <b-form-group :label="$t('TotalCostOfMaterial')">
            <b-form-input
              v-model="totalMaterialCost"
              style="width:65%"
              disabled
              type="number"
            />
          </b-form-group>
        </div>
        <div>
          <div>
            <p style="margin-bottom:5px;color:red;">
              {{ $t('IfUSelect') }}
            </p>
            <div style="display:flex;margin-bottom:10px;">
              <b-form-group
                style="width:20%"
                :label="$t('Transport')"
              >
                <b-form-checkbox v-model="transport" />
              </b-form-group>
              <b-form-group
                style="width:20%"
                :label="$t('Montage')"
              >
                <b-form-checkbox v-model="montage" />
              </b-form-group>
            </div>
            <div style="display:flex;gap: 15px">
              <div v-if="montage">
                <label>{{ $t('MontageStart') }}</label>
                <div style="display: flex;flex-direction: column;">
                  <date-picker
                    v-model="montageStart "
                    type="datetime"
                    :placeholder="$t('SelectD')"
                    format="YYYY-MM-DD HH:mm"
                  />
                  <p
                    v-if="montageStart == null && montage && isButtonSubmited"
                    style="color: red;padding-top: 5px;
                        margin-bottom: 0px;"
                  >
                    {{ $t('SelectD') }}
                  </p>
                </div>
              </div>
              <div
                v-if="montage"
                style="display: flex;gap:10px;"
              >
                <div>
                  <label style="padding-left: 25px;;">{{ $t('DurationHour') }}</label>
                  <div style="display: flex;flex-direction: row;align-items:center; gap: 8px;">
                    <b-icon-dash-circle
                      style="cursor:pointer"
                      @click="substractHour"
                    />
                    <b-form-input
                      v-model.number="durationTimeHour"
                      type="number"
                      :placeholder="$t('Hour')"
                    />
                    <b-icon-plus-circle
                      style="cursor:pointer"
                      @click="addHour"
                    />
                  </div>
                </div>
                <div>
                  <label style="padding-left: 25px;;">{{ $t('DurationMinutes') }}</label>
                  <div style="display: flex;flex-direction: row;align-items:center; gap: 8px;">
                    <b-icon-dash-circle
                      style="cursor:pointer"
                      @click="substractMinutes"
                    />
                    <b-form-input
                      v-model.number="durationTimeMinutes"
                      type="number"
                      :placeholder="$t('Minutes')"
                    />
                    <b-icon-plus-circle
                      style="cursor:pointer"
                      @click="addMinutes"
                    />
                  </div>
                </div>
              </div>
              <div v-if="montage">
                <label>{{ $t('MontageEnd') }}</label>
                <div style="display: flex;flex-direction: column;">
                  <date-picker
                    v-model="montageEnd "
                    type="datetime"
                    :placeholder="$t('SelectD')"
                    format="YYYY-MM-DD HH:mm"
                    disabled
                  />
                  <p
                    v-if="montageEnd == null && montage && isButtonSubmited"
                    style="color: red;padding-top: 5px;
                        margin-bottom: 0px;"
                  >
                    {{ $t('SelectD') }}
                  </p>
                </div>
              </div>
              <div v-if="!montage">
                <label>{{ $t('PickupDate') }}</label>
                <div style="display: flex;flex-direction: column;">
                  <date-picker
                    v-model="pickUpDate "
                    type="datetime"
                    :placeholder="$t('SelectD')"
                  />
                  <p
                    v-if="pickUpDate == null && isButtonSubmited && !montage"
                    style="color: red;padding-top: 5px;
                        margin-bottom: 0px;"
                  >
                    {{ $t('SelectD') }}
                  </p>
                </div>
              </div>
            </div>
            <div style="display:flex;gap:10px;">
              <div v-if="montage">
                <label>{{ $t('MontageCost') }}</label>
                <div style="display: flex;gap:5px">
                  <b-input
                    v-model.number="montageCost"
                    type="number"
                    :placeholder="$t('MontageCost')"
                  />
                  <b-form-select
                    v-model="currency "
                    dropup
                    required
                    :options="currencies"
                    aria-placeholder="Select order type"
                    aria-describedby="input-1-live-feedback"
                    style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                  />
                </div>
              </div>
              <div v-if="transport">
                <label>{{ $t('transportCost') }}</label>
                <div style="display: flex;gap:5px">
                  <b-input
                    v-model.number="transportCost"
                    type="number"
                    :placeholder="$t('transportCost')"
                  />
                  <b-form-select
                    v-model="currency "
                    dropup
                    required
                    :options="currencies"
                    aria-placeholder="Select order type"
                    aria-describedby="input-1-live-feedback"
                    style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                  />
                </div>
              </div>
              <div>
                <b-form-group
                  :label="$t('SellDate')"
                >
                  <date-picker
                    v-model="sellDate "
                    type="date"
                    format="YYYY-MM-DD"
                    value-type="format"
                    :placeholder="$t('SelectD')"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
          <div style="padding:15px 0px 15px 0px;">
            <table
              class="team_table"
            >
              <thead>
                <tr>
                  <th>{{ $t('PriceNoVat') }}</th>
                  <th>{{ $t('Vat') }}</th>
                  <th>{{ $t('PriceWithVat') }}</th>
                  <th>{{ $t('Discount') }}</th>
                  <th>{{ $t('DiscountedPrice') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div style="display:flex;">
                      <b-form-input
                        v-model.number="priceWithoutVAT"
                        type="number"
                        :placeholder="$t('Value')"
                        @input="calculateFromPriceWithoutVAT(priceWithoutVAT)"
                      />
                      <b-form-select
                        v-model="currency"
                        dropup
                        required
                        :options="currencies"
                        aria-placeholder="Select order type"
                        aria-describedby="input-1-live-feedback"
                        style="display: block;
                            padding: 0.469rem 0.75rem;
                            font-size: 1rem;
                            font-weight: 400;
                            line-height: 1.5;
                            color: #212529;
                            background-color: #fff;
                            background-clip: padding-box;
                            border: 1px solid #ced4da;
                            border-radius: 0.25rem;
                            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                      />
                    </div>
                  </td>
                  <td>
                    <div style="padding:5px;">
                      <b-form-radio-group
                        v-model="selectedVAT"
                        :options="vatOptions"
                        style="display:flex;gap: 10px;align-items: center;"
                        @input="calculateFromSelectedVAT(selectedVAT)"
                      />
                    </div>
                  </td>
                  <td>
                    <div style="display:flex;margin-right: 5px;">
                      <b-form-input
                        v-model.number="priceWithVAT"
                        type="number"
                        :placeholder="$t('Value')"
                        @input="calculateFromPriceWithVAT(priceWithVAT)"
                      />
                      <b-form-select
                        v-model="currency"
                        dropup
                        required
                        :options="currencies"
                        aria-placeholder="Select order type"
                        aria-describedby="input-1-live-feedback"
                        style="display: block;
                            padding: 0.469rem 0.75rem;
                            font-size: 1rem;
                            font-weight: 400;
                            line-height: 1.5;
                            color: #212529;
                            background-color: #fff;
                            background-clip: padding-box;
                            border: 1px solid #ced4da;
                            border-radius: 0.25rem;
                            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                      />
                    </div>

                  </td>
                  <td>
                    <div style="display:flex;margin-right: 5px;">
                      <b-input
                        v-model.number="discount"
                        type="number"
                        default="0"
                        :disabled="priceWithVAT == 0 || priceWithVAT == ''"
                      />
                      <b-form-select
                        v-model="discountType"
                        style="height:38px; border-radius: 0.25rem; border: 1px solid #EAECF0"
                      >
                        <option value="$">
                          $
                        </option>
                        <option value="€">
                          €
                        </option>
                        <option value="CHF">
                          CHF
                        </option>
                        <option value="%">
                          %
                        </option>
                      </b-form-select>
                    </div>
                  </td>
                  <td>
                    <div style="display:flex">

                      <b-input
                        v-model.number="discountedPrice"
                        disabled
                        type="number"
                        style="width: 90%;"
                      />
                      <b-form-select
                        v-model="currency"
                        dropup
                        required
                        :options="currencies"
                        aria-placeholder="Select order type"
                        aria-describedby="input-1-live-feedback"
                        style="display: block;
                            padding: 0.469rem 0.75rem;
                            font-size: 1rem;
                            font-weight: 400;
                            line-height: 1.5;
                            color: #212529;
                            background-color: #fff;
                            background-clip: padding-box;
                            border: 1px solid #ced4da;
                            border-radius: 0.25rem;
                            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="team_table"
            >
              <thead>
                <tr>
                  <th>{{ $t('PaidPrice') }}</th>
                  <th>{{ $t('Account') }}</th>
                  <th />
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="width:30% !important;">
                    <div style="display:flex;margin-top:10px;">
                      <b-input
                        v-model.number="paidPrice"
                        type="number"
                        style="width:220px;"
                      />
                      <b-form-select
                        v-model="currency "
                        dropup
                        required
                        :options="currencies"
                        aria-placeholder="Select order type"
                        aria-describedby="input-1-live-feedback"
                        style="display: block;
                            padding: 0.469rem 0.75rem;
                            font-size: 1rem;
                            font-weight: 400;
                            line-height: 1.5;
                            color: #212529;
                            background-color: #fff;
                            background-clip: padding-box;
                            border: 1px solid #ced4da;
                            border-radius: 0.25rem;
                            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      style="width: 70%;"
                    >
                      <vue-select
                        v-model="accountId"
                        :options="getAccountDetails"
                        :reduce="(e) => e.accountId"
                        label="name"
                        :placeholder="$t('Select')"
                        style="width: 70%;"
                      />
                    </div>
                  </td>
                  <td />
                  <td />
                  <td />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="price-container">
          <h5
            class="price-text"
            style=" padding: 7px 0px; display:flex; flex-direction: column;text-align: left;width: 33%;
              height: 65px; font-size: 16px; font-weight: 500"
          >
            {{ $t("TotalP") }}:
            <p style="font-weight: 500; background: #F5F5F5; margin-top: 7px; padding: 10px 14px; border-radius: 8px; border: 1px solid #D0D5DD">
              {{ (discountedPrice).toFixed(2) || 0 }}
            </p>
          </h5>
          <h5
            class="price-text"
            style=" padding: 7px 0px; margin-left: 15px; display: flex; flex-direction: column;text-align: left;width: 33%;
              height: 65px; font-size: 16px; font-weight: 500"
          >
            {{ $t("TotalPD") }}:
            <p style="font-weight: 500; background: #F5F5F5; margin-top: 7px; padding: 10px 14px; border-radius: 8px; border: 1px solid #D0D5DD">
              {{ (paidPrice).toFixed(2) || 0 }}
            </p>

          </h5>
          <h5
            class="price-text"
            style=" padding: 7px 0px; margin-left: 15px;display: flex; flex-direction: column;text-align: left;width: 33%;
              height: 65px; font-size: 16px; font-weight: 500"
          >
            {{ $t("BillR") }}:
            <p style="font-weight: 500; background: #F5F5F5; margin-top: 7px; padding: 10px 14px; border-radius: 8px; border: 1px solid #D0D5DD">
              {{ (discountedPrice - paidPrice).toFixed(2) || 0 }}
            </p>
          </h5>
        </div>
      </section>
      <div style="margin-left:5px;margin-top: 10px;width:100%;border:3px solid lightgray;border-radius: 6px;padding:15px">
        <b-form-group
          :label="$t('ShippingCost')"
          style="width:30%"
        >
          <div
            style="display:flex;flex-direction:column;gap:5px;"
          >
            <div style="display:flex;flex-direction:row;gap:5px;">
              <b-form-input
                v-model.number="shippings.cost"
                :placeholder="$t('Cost')"
                type="number"
              />
              <b-form-select
                v-model="shippings.currency"
                dropup
                :options="[{ value: null, text: 'None' }, ...currencies]"
                aria-placeholder="Select order type"
                aria-describedby="input-1-live-feedback"
                style="display: block;
                          padding: 0.469rem 0.75rem;
                          font-size: 1rem;
                          font-weight: 400;
                          line-height: 1.5;
                          color: #212529;
                          background-color: #fff;
                          background-clip: padding-box;
                          border: 1px solid #ced4da;
                          border-radius: 0.25rem;
                          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                :disabled="shippings.cost == null || shippings.cost == ''"
              />
            </div>
            <b-form-group
              :label="$t('paidAmount')"
              style="width: 100%"
            />
            <b-form-input
              v-model.number="shippings.paidAmount"
              :placeholder="$t('paidAmount')"
              type="number"
              :disabled="shippings.cost == null || shippings.cost == ''"
            />
            <b-form-group
              :label="$t('Notes')"
              style="width:100%"
            >
              <b-form-textarea
                v-model="shippings.notes"
                style="width:100%"
                :placeholder="$t('Select')"
                type="number"
              />
            </b-form-group>
          </div>
        </b-form-group>
        <b-form-group
          :label="$t('SelectAccount')"
          style="margin-top:5px;margin-bottom:5px;width:30%"
        >
          <vue-select
            v-model="shippings.accountId"
            :options="getAccountDetails"
            required
            label="name"
            :reduce="(account) => account.accountId"
            :placeholder="$t('SelectAccount')"
            aria-describedby="input-1-live-feedback"
            :disabled="(shippings.cost == null || shippings.cost == '') || (shippings.paidAmount == null || shippings.paidAmount == '')"
          />
          <p
            v-if="shippings.cost != null && shippings.paidAmount != null && shippings.accountId == null"
            style="color:red"
          >{{ $t('FillData') }}</p>
        </b-form-group>
      </div>
      <b-form-group />
      <div style="display: flex; flex-direction:column;margin: 10px 0px 10px 5px; padding:10px;border: 3px solid lightgray;">
        <div style="display: flex; gap: 10px; width:100%">
          <StoresDropdown
            :filiale="filiale"
            @updateFiliale="updateFiliale"
          />
          <b-form-group
            v-if="getLoggedInUser.role !== 'SalesPerson' && getLoggedInUser.role !== 'OnlineSales' "
            :label="$t('SelectSalesPerson')"
            style="width: 22%;"
          >
            <vue-select
              v-model="roleId"
              :options="getUsersMultipleRoles"
              required
              label="fullName"
              :placeholder="$t('SelectSalesPerson')"
              aria-describedby="input-1-live-feedback"
              style="width: 100%;"
            />
          </b-form-group>
        </div>
        <b-form-group
          style="width:100%"
          :label="$t('InoviceRemark')"
        >
          <b-form-textarea
            v-model="invoiceRemark"
            :placeholder="$t('Write...')"
            style="width:100%"
          />
        </b-form-group>
      </div>
      <div style="display: flex;">
        <AddFile
          ref="addFile1"
          :folder="'OrderPhotos'"
          :label-text="$t('UploadSketch')"
          :file-data-type="'Scetch'"
          :file-name="createFileDataDto"
        />
        <AddFile
          ref="addFile2"
          :folder="'OrderPhotos'"
          :label-text="$t('UploadContract')"
          :file-data-type="'Contract'"
          :file-name="createFileDataDto"
        />
        <AddFile
          ref="addFile3"
          :folder="'OrderPhotos'"
          :label-text="$t('OtherDocuments')"
          :file-data-type="'OtherDocument'"
          :file-name="createFileDataDto"
        />
      </div>
      <b-form-group
          :label="$t('Notes')"
          style="margin-top: 20px;"
        >
          <b-form-textarea
            id="textarea"
            v-model="description"
            :placeholder="$t('RequestedChangesByCustomer')"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            {{ $t('RequiredFields') }}
          </b-form-invalid-feedback>
        </b-form-group>
    </div>
    <div class="buttonsEverywhere">
      <button
        type="button"
        variant="none"
        style="margin-top: 20px;"
        class="buttonSubmit"
        @click="onSubmit()"
      >
        {{ $t('Submit') }}
      </button>
      <b-button
        type="reset"
        variant="danger"
        class="buttonCancel"
        style="margin-right: 15px; margin-top: 20px; background: white; color: black; border-color: white;font-weight: 400;"
        @click="onCancel"
      >
        {{ $t('Cancel') }}
      </b-button>
    </div>
  </b-modal>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment'
import AddFile from '@/modals/AddFile.vue'
import StoresDropdown from '../../StoresDropdown.vue'

export default {
  components: {
    DatePicker,
    StoresDropdown,
    AddFile,
  },
  props: ['orderIdOfOrder', 'nameOfOrder'],
  data() {
    return {
      description: null,
      createHouseItemRequestDtos: [],
      totalPerMeter: 0,
      pageNumberFilter: 1,
      selectedFloorIndex: 0,
      selectedRoomIndex: 0,
      selectedWindowIndex: 0,
      collapsedStates: {},
      collapsedStatesFloor: {},
      collapsedStatesRoom: {},
      collapsedStatesObject: {},
      collapsedStatesObjectMaterials: {},
      hingeTypes: [
        { name: this.$t('Decorative'), value: 'Decorative' },
        { name: this.$t('Closed'), value: 'Closed' },
      ],
      sewingTypes: [
        {
          name: 'Sistemi Amerikan 3m', value: 'American_3m', meter: 3, materialVariantId: 'cd9e5ed2-df10-4ba4-a6db-08dd09677ee2', materialVariantName: 'Shiritat Amerikan',
        },
        {
          name: 'Sistemi Amerikan 2.8m', value: 'American_2_8m', meter: 2.8, materialVariantId: 'cd9e5ed2-df10-4ba4-a6db-08dd09677ee2', materialVariantName: 'Shiritat Amerikan',
        },
        {
          name: 'Fallta te rregullta 3m', value: 'FalltaReglar_3m', meter: 3, materialVariantId: 'dea85c63-6242-4010-a6ee-08dd09677ee2', materialVariantName: 'Shirita Fallta',
        },
        {
          name: 'Fallta te rregullta 2.5m', value: 'FalltaReglar_2_5m', meter: 2.5, materialVariantId: 'dea85c63-6242-4010-a6ee-08dd09677ee2', materialVariantName: 'Shirita Fallta',
        },
        {
          name: 'Fallta te rregullta 2m', value: 'FalltaReglar_2m', meter: 2, materialVariantId: 'dea85c63-6242-4010-a6ee-08dd09677ee2', materialVariantName: 'Shirita Fallta',
        },
        {
          name: 'Fallta Zvicrrane 3m', value: 'FalltaSwiss_3m', meter: 3, materialVariantId: '12f0c104-a95a-47c8-a6dc-08dd09677ee2', materialVariantName: 'Shiritat Zvicerran',
        },
        {
          name: 'Fallta Zvicrrane 2.7m', value: 'FalltaSwiss_2_7m', meter: 2.7, materialVariantId: '12f0c104-a95a-47c8-a6dc-08dd09677ee2', materialVariantName: 'Shiritat Zvicerran',
        },
        {
          name: 'Fallta Zvicrrane 2m', value: 'FalltaSwiss_2_5m', meter: 2.5, materialVariantId: '12f0c104-a95a-47c8-a6dc-08dd09677ee2', materialVariantName: 'Shiritat Zvicerran',
        },
        {
          name: 'Qepje me vega 2.5m', value: 'Veg_2_5m', meter: 2.5, materialVariantId: '70ff128e-c889-424a-a6ed-08dd09677ee2', materialVariantName: 'Shirita me vega',
        },
        {
          name: 'Qepje Fallta me vrime 3m', value: 'Holes_3m', meter: 3, materialVariantId: 'dea85c63-6242-4010-a6ee-08dd09677ee2', materialVariantName: 'Shirita Fallta',
        },
        {
          name: 'Qepje Fallta me vrime 2.5m', value: 'Holes_2_5m', meter: 2.5, materialVariantId: 'dea85c63-6242-4010-a6ee-08dd09677ee2', materialVariantName: 'Shirita Fallta',
        },
        {
          name: 'Qepje me rrudhje 4m', value: 'Wrinkle_4m', meter: 4, materialVariantId: 'f4ad44f0-55f5-476c-a6ef-08dd09677ee2', materialVariantName: 'Shirita me rrudha',
        },
        {
          name: 'Qepje me rrudhje 3m', value: 'Wrinkle_3m', meter: 3, materialVariantId: 'f4ad44f0-55f5-476c-a6ef-08dd09677ee2', materialVariantName: 'Shirita me rrudha',
        },
        {
          name: 'Qepje shirit drejt', value: 'StraightBand', meter: 1, materialVariantId: '0974f5ce-e07e-4ac0-a6f0-08dd09677ee2', materialVariantName: 'Shirita drejt',
        },
      ],
      paidPrice: 0,
      currency: '€',
      shippings: {
        cost: null,
        accountId: null,
        currency: '€',
        notes: '',
        paidAmount: null,
      },
      currencies: ['€', '$', 'CHF'],
      discountType: '€',
      sellDate: moment().format('YYYY-MM-DD'),
      totalPrice: 0,
      discount: 0,
      shipmentPrice: null,
      shipmentCode: null,
      paidAmount: null,
      remark: null,
      montageCost: 0,
      transportCost: 0,
      durationTimeMinutes: 0,
      durationTimeHour: 0,
      transport: true,
      montage: true,
      pickUpDate: null,
      montageStart: null,
      montageEnd: null,
      roleId: {
        fullName: 'Select sales',
        userID: null,
      },
      invoiceRemark: null,
      accountId: null,
      isButtonSubmited: false,
      filiale: { storeId: null, storeName: 'No filiale', storeGLN: null },
      taxPrice: 0,
      amount: 1,
      priceWithoutVAT: 0,
      selectedVAT: 18,
      vatOptions: [
        { text: '18%', value: 18 },
        { text: '8%', value: 8 },
        { text: '0%', value: 0 },
      ],
      priceWithVAT: 0,
      oldPrice: 0,
      totalVat: 0,
      createFileDataDto: [],
    };
  },
  computed: {
    ...mapGetters([
      'getMaterialsByArticleNum',
      'getMaterialVariantColorNameLike',
      'getMaterialsVByMeter',
      'getMaterialsVByPart',
      'getMaterialsVByPackage',
      'getMaterialsVBySet',
      'getMaterialsVByPair',
      'getMaterialsVByVerse',
      'getMaterialsVByVMeter_Square',
      'getSellPriceByVariant',
      'getClients',
      'getOrderTypes',
      'getMeasermentsById',
      'getFirstNames',
      'getTotalItemsForClients',
      'getClient',
      'getLanguage',
      'getAccountDetails',
      'getUsersMultipleRoles',
      'getLoggedInUser',
      'getAllStoresDropdown',
      'getNewInvoiceByOrderNumber',
      'getLocationsMoving',
      'getPaidAdmountByOrder',
      'getItemsByOrderId',
    ]),
    discountedPrice: {
      get() {
        if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
          return this.priceWithVAT - this.discount;
        } if (this.discountType === '%') {
          if (this.priceWithVAT === 0) {
            return 0; // Avoid division by zero
          }
          const percentageDiscount = (this.discount / 100) * this.priceWithVAT;
          return (this.priceWithVAT - percentageDiscount).toFixed(2);
        }

        // Default to 0 when no discount type is selected
        return 0;
      },
      set(value) {
        // You can add validation here if needed
        if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
          this.discount = this.priceWithVAT - value;
        } else if (this.discountType === '%') {
          if (this.priceWithVAT === 0) {
            this.discount = 0; // Avoid division by zero
          } else {
            const percentageDiscount = (this.priceWithVAT - value) / (this.priceWithVAT * 100);
            this.discount = percentageDiscount;
          }
        }
      },
    },
    totalMaterialCost() {
      let totalCost = 0;

      const calculateCost = (materialDtos) => {
        materialDtos.forEach((material) => {
          const price = parseFloat(material.pricePerMeterWithDiscount);
          const quantityOrLength = material.quantity > 0 ? material.quantity : parseFloat(material.length);
          totalCost += price * quantityOrLength;
        });
      };

      this.createHouseItemRequestDtos.forEach((house) => {
        house.createFloorRequestDtos.forEach((floor) => {
          floor.createRoomRequestDtos.forEach((room) => {
            room.createWindowRequestDtos.forEach((window) => {
              calculateCost(window.materialVariantInStoreDtos);
              if (window.createWindowDecorativeRequestDtos && window.createWindowDecorativeRequestDtos.length > 0) {
                window.createWindowDecorativeRequestDtos.forEach((decorativeWindow) => {
                  calculateCost(decorativeWindow.materialVariantInStoreDtos);
                });
              }
            });
          });
        });
      });

      return Math.ceil(totalCost);
    },
  },
  watch: {
    getItemsByOrderId(value) {
      if (value && Object.keys(value).length > 0) {
        this.createHouseItemRequestDtos = value.createHouseItemRequestDtos
        this.paidPrice = value.paidAmount
        this.priceWithVAT = value.price
        this.invoiceRemark = value.invoiceRemark
        this.discount = value.discount
        this.accountId = value.accountId
        this.roleId = {
          fullName: value.salesUserName,
          userID: value.salesUserId,
        }
        this.montageStart = new Date(value.montageStart);
        this.montageEnd = new Date(value.montageEnd);
        this.pickUpDate = value.pickUpDate
        this.shippings = {
          cost: value.shippingCostRequestDto?.cost,
          accountId: value.shippingCostRequestDto?.accountId,
          currency: value.shippingCostRequestDto?.currency,
          notes: value.shippingCostRequestDto?.notes,
          paidAmount: value.shippingCostRequestDto?.paidAmount,
        }
        this.selectedVAT = value.taxRate
        this.description = value.notes
        this.calculateDurationFromTo()
      }
    },
    'shippings.cost': function (newCost) {
      if (newCost < 0 || newCost == '') {
        this.shippings.cost = null
        this.shippings.paidAmount = 0;
      }
    },
    discountType(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value) {
          this.discount = 0
        }
      }, 0);
    },
    'shippings.paidAmount': function (newCost) {
      if (newCost > this.shippings.cost) {
        this.shippings.paidAmount = 0;
      }
      if (newCost < 0 || newCost == '') {
        this.shippings.paidAmount = null
        this.shippings.accountId = null
      }
    },
    montageCost(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.montageCost = 0
        }
        this.updateTotalPrice()
      }, 0);
    },
    transportCost(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.transportCost = 0
        }
        this.updateTotalPrice()
      }, 0);
    },
    totalMaterialCost(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.totalMaterialCost = 0
        }
        this.updateTotalPrice()
      }, 0);
    },
    montageStart(value) {
      if (value) {
        this.calculateMontageEnd()
      }
    },
    durationTimeHour(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value < 0) {
          this.durationTimeHour = 0;
        } else if (value === '') {
          this.durationTimeHour = 0;
        } else {
          const fullHours = Math.floor(value);
          const fractionalPart = value - fullHours;

          this.durationTimeHour = fullHours;
          this.durationTimeMinutes += Math.round(fractionalPart * 60);
        }
        this.calculateMontageEnd()
      }, 0);
    },
    durationTimeMinutes(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value < 0) {
          this.durationTimeMinutes = 0
        }
        if (value == '') {
          this.durationTimeMinutes = 0;
        }
        if (value >= 60) {
          const totalHours = Math.floor(value / 60);
          this.durationTimeHour += totalHours;

          const fractionalPart = (value / 60) - totalHours;
          const remainingMinutes = Math.round(fractionalPart * 60);

          this.durationTimeMinutes = remainingMinutes;
        }
        if (value < 0 && this.durationTimeHour > 0) {
          this.durationTimeHour -= 1
          this.durationTimeMinutes = 45
        }
        this.calculateMontageEnd()
      }, 0);
    },
    montage(value) {
      if (value == true) {
        this.pickUpDate = null
      } else {
        this.montageStart = null
        this.montageEnd = null
        this.durationTimeMinutes = 0
        this.durationTimeHour = 0
        this.montageCost = 0
      }
    },
    transport(value) {
      if (value == false) {
        this.transportCost = 0
      }
    },
    priceWithoutVAT(newPrice) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (newPrice == '' || newPrice < 0 || newPrice == null) {
          this.priceWithVAT = 0
          this.priceWithoutVAT = 0
        }
      }, 0);
    },
    priceWithVAT(newPrice) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (newPrice == '' || newPrice < 0 || newPrice == null) {
          this.priceWithVAT = 0
          this.priceWithoutVAT = 0
        }
      }, 0);
      if (newPrice < this.discount) {
        this.discount = 0;
      }
    },
    // eslint-disable-next-line func-names
    discount(newDiscount) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
        if (newDiscount > this.priceWithVAT && newDiscount > this.discountedPrice) {
          this.discount = 0;
        }
      } else if (this.discountType === '%') {
        if (this.getLoggedInUser.role === 'SalesPerson' && newDiscount > 10) {
          this.discount = 0;
        } else if (this.getLoggedInUser.role === 'Manager' && newDiscount > 20) {
          this.discount = 0;
        } else if (this.getLoggedInUser.role !== 'Manager' && this.getLoggedInUser.role !== 'SalesPerson' && this.getLoggedInUser.role !== 'CEO' && this.getLoggedInUser.role !== 'Admin' && newDiscount > 5) {
          this.discount = 0;
        } else if (newDiscount > 100) {
          this.discount = 0;
        }
      }
      this.timeoutId = setTimeout(() => {
        if (newDiscount === null || newDiscount === '' || newDiscount < 0) {
          this.discount = 0;
        }
      }, 0);
    },
  },
  mounted() {
    this.accountDetails({ isActive: true, bankId: null })
  },
  methods: {
    ...mapActions(['filterVariantByColor', 'resetLoadItemsByOrderId', 'accountDetails', 'orderDetailsOrderId', 'editOrderItemsOrder', 'loadPriceByVariant', 'resetAllFiltered']),
    updateFiliale(newFiliale) {
      this.filiale = newFiliale;
    },
    calculateFromPriceWithVAT(value) {
      if (this.amount > 0) {
        this.priceWithoutVAT = parseFloat((value / (1 + this.selectedVAT / 100)).toFixed(2));
        this.valueWithVAT = parseFloat((value * this.amount).toFixed(2));
      } else {
        this.priceWithoutVAT = parseFloat((value / (1 + this.selectedVAT / 100)).toFixed(2));
      }
    },
    calculateFromSelectedVAT(value) {
      if (this.amount > 0) {
        this.priceWithVAT = parseFloat((this.priceWithoutVAT * (1 + value / 100)).toFixed(2));
        this.valueWithVAT = parseFloat((this.priceWithVAT * this.amount).toFixed(2));
      } else if (this.priceWithoutVAT > 0) {
        this.priceWithVAT = parseFloat((this.priceWithoutVAT * (1 + value / 100)).toFixed(2));
      }

      if (this.priceWithVAT > 0) {
        this.priceWithoutVAT = parseFloat((this.priceWithVAT / (1 + value / 100)).toFixed(2));
      }
    },
    calculateFromPriceWithoutVAT(value) {
      if (this.amount > 0) {
        this.priceWithVAT = parseFloat((value * (1 + this.selectedVAT / 100)).toFixed(2));
        this.valueWithVAT = parseFloat((this.priceWithVAT * this.amount).toFixed(2));
      } else {
        this.priceWithVAT = parseFloat((value * (1 + this.selectedVAT / 100)).toFixed(2));
      }
    },
    updateTotalPrice() {
      this.priceWithVAT = this.totalMaterialCost + this.montageCost + this.transportCost;
      this.calculateFromPriceWithVAT(this.priceWithVAT)
    },
    calculateDurationFromTo() {
      if (this.montageStart && this.montageEnd) {
        const fromDate = new Date(this.montageStart);
        const toDate = new Date(this.montageEnd);

        const diffInMinutes = (toDate - fromDate) / 60000;

        this.durationTimeHour = Math.floor(diffInMinutes / 60);
        this.durationTimeMinutes = diffInMinutes % 60;
      }
    },
    calculateMontageEnd() {
      if (this.montageStart && this.durationTimeHour != null && this.durationTimeMinutes != null) {
        const startDate = new Date(this.montageStart);

        startDate.setHours(startDate.getHours() + this.durationTimeHour);
        startDate.setMinutes(startDate.getMinutes() + this.durationTimeMinutes);

        startDate.setSeconds(0);

        this.montageEnd = startDate;

        console.log('Montage End:', this.montageEnd);
      }
    },
    addHour() {
      this.durationTimeHour++;
      this.calculateMontageEnd();
    },
    substractHour() {
      this.durationTimeHour--;
      this.calculateMontageEnd();
    },
    addMinutes() {
      this.durationTimeMinutes += 15
      this.calculateMontageEnd();
    },
    substractMinutes() {
      this.durationTimeMinutes -= 15
      this.calculateMontageEnd();
    },
    addHouse() {
      const houseNumber = this.createHouseItemRequestDtos.length + 1;
      this.createHouseItemRequestDtos.push({
        name: `Shtepia nr ${houseNumber}`, createFloorRequestDtos: [], editable: false,
      });
    },
    updateWindowDecorativeWidth(value) {
      if (value.createWindowDecorativeRequestDtos && value.createWindowDecorativeRequestDtos.length > 0) {
        value.createWindowDecorativeRequestDtos.forEach((item) => {
          item.width = value.width;
        });
      }
    },
    updateWindowDecorativeHeight(value) {
      if (value.createWindowDecorativeRequestDtos && value.createWindowDecorativeRequestDtos.length > 0) {
        value.createWindowDecorativeRequestDtos.forEach((item) => {
          item.height = value.height;
        });
      }
    },
    updateMaterialVariantId(value, material) {
      if (value != null) {
        material.materialVariantId = value.materialVariantId
        material.pricePerMeter = value.sellPrice || 0
        material.name = value.materialVariantName
        material.pricePerMeterWithDiscount = value.sellPrice || 0
        material.totalPerMeter = ((value.sellPrice || 0) * (material.length || material.quantity || 0)).toFixed(2)
      } else {
        material.materialVariantId = null
        material.pricePerMeter = 0
        material.pricePerMeterWithDiscount = 0
        material.name = null
        material.totalPerMeter = 0
      }
    },
    checkMontage(value, window) {
      if (value == false) {
        window.montageStart = null
        window.montageEnd = null
      }
    },
    updateDecorativeName(index, window) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos[this.selectedRoomIndex].createWindowRequestDtos[this.selectedWindowIndex].createWindowDecorativeRequestDtos[0].name = `${window.name} - ${window.hingType}`
    },
    updateDecorative(index, window, hingeTypeValues) {
      const selectedHingeType = hingeTypeValues[hingeTypeValues.length - 1];

      const windowDecoratives = this.createHouseItemRequestDtos[index]
        .createFloorRequestDtos[this.selectedFloorIndex]
        .createRoomRequestDtos[this.selectedRoomIndex]
        .createWindowRequestDtos[this.selectedWindowIndex]
        .createWindowDecorativeRequestDtos;
      if (window.hingType && window.hingType.length > 0) {
        window.hingType.forEach((hingeType) => {
          const existingItem = windowDecoratives.find((item) => {
            return item.name.includes(window.name.split(' - ')[0]) && item.name.includes(hingeType);
          });

          if (!existingItem) {
            windowDecoratives.push({
              name: `${window.name} - ${selectedHingeType}`,
              width: window.width,
              height: window.height,
              sewingType: null,
              orderOtherService: {
                pattern: true,
                sewing: true,
                transport: true,
                montage: true,
              },
              materialVariantInStoreDtos: [
                {
                  materialVariantId: null,
                  name: null,
                  measurementType: 'Meter',
                  quantity: 0,
                  length: 0,
                  pricePerMeter: 0,
                  pricePerMeterWithDiscount: 0,
                  totalPerMeter: 0,
                },
              ],
              montageStart: null,
              montageEnd: null,
              hingType: selectedHingeType,
            });
          }
        });

        windowDecoratives.forEach((decorativeItem, idx) => {
          const shouldRemove = !window.hingType.some((hingeType) => { return decorativeItem.name.includes(hingeType) });
          if (shouldRemove) {
            windowDecoratives.splice(idx, 1);
          }
        });
      } else {
        this.createHouseItemRequestDtos[index]
          .createFloorRequestDtos[this.selectedFloorIndex]
          .createRoomRequestDtos[this.selectedRoomIndex]
          .createWindowRequestDtos[this.selectedWindowIndex]
          .createWindowDecorativeRequestDtos = [];
      }
    },
    toggleEditWindowName(index, floorIndex, roomIndex, windowIndex, window) {
      this.$set(this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos, windowIndex, {
        ...this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos[windowIndex],
        editable: !this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos[windowIndex].editable,
      });
      if (window.hingType) {
        this.updateDecorativeName(index, window)
      }
    },
    saveWindowName(index, floorIndex, roomIndex, windowIndex, window) {
      this.$set(this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos, windowIndex, {
        ...this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos[windowIndex],
        editable: false,
      });
      if (window.hingType) {
        this.updateDecorativeName(index, window)
      }
    },
    saveHouseName(index) {
      this.createHouseItemRequestDtos[index].editable = false;
    },
    toggleEditHouseName(index) {
      this.createHouseItemRequestDtos[index].editable = !this.createHouseItemRequestDtos[index].editable;
    },
    toggleEditFloorName(index, floorIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].editable = !this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].editable;
    },
    saveFloorName(index, floorIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].editable = false;
    },
    toggleEditRoomName(index, floorIndex, roomIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].editable = !this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].editable;
    },
    saveRoomName(index, floorIndex, roomIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].editable = false;
    },
    handleSewingMethod(window) {
      this.updateMaterialLengthOrQuantity(window)
      this.updateMaterialAmerican(window)
    },
    updateMaterialAmerican(window) {
      if (window.sewingType) {
        const selectedSewingType = this.sewingTypes.find(
          (type) => { return type.value === window.sewingType },
        );
        this.loadPriceByVariant({
          materialVariantId: selectedSewingType.materialVariantId,
        }).then((response) => {
          const existingItemIndex = window.materialVariantInStoreDtos.findIndex(
            (item) => { return item.parameter === 'Shirit' },
          );

          if (existingItemIndex !== -1) {
            window.materialVariantInStoreDtos[existingItemIndex] = {
              materialVariantId: selectedSewingType.materialVariantId,
              name: selectedSewingType.materialVariantName,
              measurementType: 'Meter',
              quantity: 0,
              length: (selectedSewingType.meter * (window.width || 0) + 0.1)?.toFixed(2),
              pricePerMeter: response,
              pricePerMeterWithDiscount: response,
              parameter: 'Shirit',
              totalPerMeter: (response * (selectedSewingType.meter * (window.width || 0) + 0.1))?.toFixed(2),
            };
          } else {
            window.materialVariantInStoreDtos.unshift({
              materialVariantId: selectedSewingType.materialVariantId,
              name: selectedSewingType.materialVariantName,
              measurementType: 'Meter',
              quantity: 0,
              length: (selectedSewingType.meter * (window.width || 0) + 0.1)?.toFixed(2),
              pricePerMeter: response,
              pricePerMeterWithDiscount: response,
              parameter: 'Shirit',
              totalPerMeter: (response * (selectedSewingType.meter * (window.width || 0) + 0.1))?.toFixed(2),
            });
          }
        }).catch((error) => {
          console.error('Error loading price:', error);
        });
      } else {
        window.materialVariantInStoreDtos = window.materialVariantInStoreDtos.filter(
          (item) => { return item.parameter !== 'Shirit' },
        );
      }
    },
    updateMaterialLengthOrQuantity(window) {
      if (!window.sewingType || window.width == null || window.width === '') {
        window.materialVariantInStoreDtos.forEach((material) => {
          material.quantity = 0;
        });
      }
      const selectedSewingType = this.sewingTypes.find(
        (type) => { return type.value === window.sewingType },
      );
      if (selectedSewingType) {
        const meter = selectedSewingType.meter ?? 0;
        const width = window.width ?? 0;
        const calculatedValue = meter * width;
        window.materialVariantInStoreDtos.forEach((material) => {
          material.quantity = (calculatedValue + 0.1)?.toFixed(2);
          material.length = 0;
          material.totalPerMeter = (calculatedValue + 0.1)?.toFixed(2) * material.pricePerMeterWithDiscount
        });
      }
    },
    calculateTotalPerMeter(price, length, material) {
      this.totalPerMeter = price * length
      material.totalPerMeter = (price * length).toFixed(2)
    },
    resetMaterialName(material, window) {
      material.name = null;
      material.pricePerMeter = 0
      material.pricePerMeterWithDiscount = 0
      this.resetAllFiltered()
      this.updateMaterialLengthOrQuantity(window)
    },
    nextPage(measurement, query) {
      this.pageNumberFilter += 1
      this.filterVariantByColor({
        name: query,
        measurementType: measurement,
        pageNumber: this.pageNumberFilter,
        pageSize: 15,
      });
    },
    previousPage(measurement, query) {
      this.pageNumberFilter -= 1
      this.filterVariantByColor({
        name: query,
        measurementType: measurement,
        pageNumber: this.pageNumberFilter,
        pageSize: 15,
      });
    },
    searchMaterial(measurement, query) {
      this.searchQuery = query
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.filterVariantByColor({
          name: query,
          measurementType: measurement,
          pageNumber: 1,
          pageSize: 15,
        });
      }, 500);
    },
    getMaterialOptions(measurementType) {
      let measurement
      if (this.getMaterialVariantColorNameLike.length > 0) {
        measurement = this.getMaterialVariantColorNameLike
      } else if (measurementType === 'Meter') {
        measurement = this.getMaterialsVByMeter;
      } else if (measurementType === 'Package') {
        measurement = this.getMaterialsVByPackage;
      } else if (measurementType === 'Set') {
        measurement = this.getMaterialsVBySet;
      } else if (measurementType === 'Pair') {
        measurement = this.getMaterialsVByPair;
      } else if (measurementType === 'Verse') {
        measurement = this.getMaterialsVByVerse;
      } else if (measurementType === 'Meter_Square') {
        measurement = this.getMaterialsVByVMeter_Square;
      } else {
        measurement = this.getMaterialsVByPart;
      }


      return measurement;
    },
    addFloor(index) {
      const newFloor = this.createHouseItemRequestDtos[index].createFloorRequestDtos.length + 1;
      this.createHouseItemRequestDtos[index].createFloorRequestDtos.push({ name: `Kati nr ${newFloor}`, createRoomRequestDtos: [], editable: false });
    },
    removeHouse(index) {
      this.createHouseItemRequestDtos.splice(index, 1);
    },
    removeFloor(index, floorIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos.splice(floorIndex, 1);
      this.selectedFloorIndex = 0;
      this.selectedRoomIndex = 0;
      this.selectedWindowIndex = 0
    },
    removeMaterial(index, materialIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos[this.selectedRoomIndex].createWindowRequestDtos[this.selectedWindowIndex].materialVariantInStoreDtos.splice(materialIndex, 1)
    },
    removeMaterialDecorative(index, windowDecorativeIndex, materialIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos[this.selectedRoomIndex].createWindowRequestDtos[this.selectedWindowIndex].createWindowDecorativeRequestDtos[windowDecorativeIndex].materialVariantInStoreDtos.splice(materialIndex, 1)
    },
    selectFloorToAddRoom(index, floorIndex) {
      this.selectedFloorIndex = floorIndex;
      this.selectedRoomIndex = 0;
      this.selectedWindowIndex = 0;
    },
    selectRoomToAddObject(index, floorIndex, roomIndex) {
      this.selectedRoomIndex = roomIndex;
      this.selectedWindowIndex = 0;
    },
    selectWindowToObject(index, floorIndex, roomIndex, windowIndex) {
      this.selectedWindowIndex = windowIndex;
    },
    addRoom(index) {
      const newRoom = this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos.length + 1;
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos.push({ name: `Dhoma nr ${newRoom}`, createWindowRequestDtos: [], editable: false });
    },
    removeRoom(index, floorIndex, roomIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos.splice(roomIndex, 1);
      this.selectedFloorIndex = 0;
      this.selectedRoomIndex = 0;
      this.selectedWindowIndex = 0;
    },
    removeWindow(index, floorIndex, roomIndex, windowIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos.splice(windowIndex, 1);
      this.selectedFloorIndex = 0;
      this.selectedRoomIndex = 0;
      this.selectedWindowIndex = 0;
    },
    addWindow(index) {
      const newWindow = this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos[this.selectedRoomIndex].createWindowRequestDtos.length + 1;
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos[this.selectedRoomIndex].createWindowRequestDtos.push({
        name: `Objekti nr ${newWindow}`,
        width: 0,
        height: 0,
        sewingType: null,
        hingType: null,
        orderOtherService: {
          pattern: true,
          sewing: true,
          transport: true,
          montage: true,
        },
        materialVariantInStoreDtos: [
          {
            materialVariantId: null,
            name: null,
            measurementType: 'Meter',
            quantity: 0,
            length: 0,
            pricePerMeter: 0,
            pricePerMeterWithDiscount: 0,
            totalPerMeter: 0,
          },
        ],
        montageStart: null,
        montageEnd: null,
        createWindowDecorativeRequestDtos: [],
      });
    },
    addMaterialObject(index, window) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos[this.selectedRoomIndex].createWindowRequestDtos[this.selectedWindowIndex].materialVariantInStoreDtos.push({
        materialVariantId: null,
        name: null,
        measurementType: 'Meter',
        quantity: 0,
        length: 0,
        pricePerMeter: 0,
        pricePerMeterWithDiscount: 0,
        totalPerMeter: 0,
      });
      this.handleSewingMethod(window)
    },
    addMaterialObjectDecorative(index, window, windowDecorativeIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[this.selectedFloorIndex].createRoomRequestDtos[this.selectedRoomIndex].createWindowRequestDtos[this.selectedWindowIndex].createWindowDecorativeRequestDtos[windowDecorativeIndex].materialVariantInStoreDtos.push({
        materialVariantId: null,
        name: null,
        measurementType: 'Meter',
        quantity: 0,
        length: 0,
        pricePerMeter: 0,
        pricePerMeterWithDiscount: 0,
        totalPerMeter: 0,
      });
      this.handleSewingMethod(window)
    },
    toggleCollapse(index) {
      this.$set(this.collapsedStates, index, !this.collapsedStates[index]);
    },
    isCollapsed(index) {
      return !!this.collapsedStates[index];
    },
    toggleCollapseFloor(index) {
      this.$set(this.collapsedStatesFloor, index, !this.collapsedStatesFloor[index]);
    },
    isCollapsedFloor(index) {
      return !!this.collapsedStatesFloor[index];
    },
    toggleCollapseRoom(index) {
      this.$set(this.collapsedStatesRoom, index, !this.collapsedStatesRoom[index]);
    },
    isCollapsedRoom(index) {
      return !!this.collapsedStatesRoom[index];
    },
    toggleCollapseObject(index) {
      this.$set(this.collapsedStatesObject, index, !this.collapsedStatesObject[index]);
    },
    isCollapsedObject(index) {
      return !!this.collapsedStatesObject[index];
    },
    toggleCollapseObjectMaterials(index) {
      this.$set(this.collapsedStatesObjectMaterials, index, !this.collapsedStatesObjectMaterials[index]);
    },
    isCollapsedObjectMaterials(index) {
      return !!this.collapsedStatesObjectMaterials[index];
    },
    async onSubmit() {
      this.isButtonSubmited = true

      const hasInvalidData = this.createHouseItemRequestDtos.length === 0
        || this.createHouseItemRequestDtos.some((house) => {
          return house.createFloorRequestDtos.length === 0
          || house.createFloorRequestDtos.some((floor) => {
            return floor.createRoomRequestDtos.length === 0
            || floor.createRoomRequestDtos.some((room) => {
              return room.createWindowRequestDtos.length === 0
              || room.createWindowRequestDtos.some((window) => {
                return window.width <= 0 || window.height <= 0 || !window.sewingType
                || window.materialVariantInStoreDtos.length === 0
                || window.materialVariantInStoreDtos.some((material) => { return !material.name && material.quantity < 0 && material.length < 0 && material.pricePerMeterWithDiscount })
              })
            })
          })
        });


      if (hasInvalidData) {
        return;
      }
      if (this.montage && this.montageStart == null && this.montageEnd == null) {
        return;
      }
      if (!this.montage && this.pickUpDate == null) {
        return;
      }
      if (this.shippings.cost != null) {
        if (this.shippings.cost != null && this.shippings.paidAmount != null && this.shippings.accountId == null) {
          return
        }
      }
      await this.$refs.addFile1.uploadFile();
      await this.$refs.addFile2.uploadFile();
      await this.$refs.addFile3.uploadFile();
      const objekti = {
        createHouseItemRequestDtos: this.createHouseItemRequestDtos,
        createFileDataRequestDtos: this.createFileDataDto,
        pickUpInStore: false,
        salesUserId: this.roleId.userID,
        storeId: this.filiale.storeId,
        taxRate: this.selectedVAT,
        montageStart: this.montageStart,
        montageEnd: this.montageEnd,
        price: this.priceWithVAT,
        paidAmount: this.paidPrice,
        paidNotes: null,
        discount: this.discount,
        description: this.description,
        currency: '$',
        accountId: this.accountId,
        pickUpDate: this.pickUpDate,
        pickUpLocationId: this.filiale.storeId,
        shippingCostRequestDto: this.shippings,
        invoiceRemark: this.invoiceRemark,
        sellDate: this.sellDate,
        orderId: this.orderIdOfOrder,
        orderType: 'InStore',
      }
      objekti.createHouseItemRequestDtos.forEach((houseItem) => {
        delete houseItem.editable;
        houseItem.createFloorRequestDtos.forEach((floorItem) => {
          delete floorItem.editable;
          floorItem.createRoomRequestDtos.forEach((roomItem) => {
            delete roomItem.editable;
            roomItem.createWindowRequestDtos.forEach((windowItem) => {
              if (windowItem.sewingType && windowItem.sewingType.value) {
                windowItem.sewingType = windowItem.sewingType.value;
              }

              delete windowItem.editable
              delete windowItem.hingType
              delete windowItem.previousHingType;
              windowItem.materialVariantInStoreDtos.forEach((variant) => {
                const quantity = variant.quantity;
                const length = parseFloat(variant.length) || 0;
                if (quantity > 0 || length > 0) {
                  variant.quantity = Math.max(quantity, length);
                } else {
                  variant.quantity = 0;
                }
                delete variant.length;
              });
              windowItem.createWindowDecorativeRequestDtos.forEach((windowVariant) => {
                if (windowVariant.sewingType && windowVariant.sewingType.value) {
                  windowVariant.sewingType = windowVariant.sewingType.value;
                }
                delete windowVariant.editable;
                windowVariant.name = `${windowItem.name} - ${windowVariant.hingType}`;
                windowVariant.materialVariantInStoreDtos = windowVariant.materialVariantInStoreDtos.map((variant) => {
                  if (variant.name === null) {
                    return [];
                  }
                  const quantity = variant.quantity;
                  const length = parseFloat(variant.length) || 0;
                  if (quantity > 0 || length > 0) {
                    variant.quantity = Math.max(quantity, length);
                  } else {
                    variant.quantity = 0;
                  }
                  delete variant.length;
                  delete variant.parameter;
                  return variant;
                }).filter(Boolean);
              });
            });
          });
        });
      });
      this.editOrderItemsOrder({
        object: objekti,
        successCallback: () => {
        //   this.orderDetailsOrderId({
        //     orderId: this.orderIdOfOrder,
        //   })
        },
      })
      this.$refs.modal.hide();
      this.resetForm()
    },
    onCancel() {
      this.$refs.modal.hide();
      this.resetForm()
    },
    resetForm() {
      this.resetLoadItemsByOrderId()
      this.createFileDataDto = [];
      this.shippings.cost = ''
      this.shippings.currency = null
      this.shippings.accountId = null
      this.shippings.notes = ''
      this.shippings.paidAmount = ''
      this.durationTimeHour = 0
      this.durationTimeMinutes = 0
      this.montageCost = 0
      this.transportCost = 0
      this.price = 0
      this.discount = 0
      this.paidPrice = 0
      this.accountId = null
      this.roleId.userID = null
      this.roleId.fullName = 'Select sales'
      this.invoiceRemark = null
      this.isButtonSubmited = false
      this.createHouseItemRequestDtos = []
      this.orderNumber = '';
      this.client = false;
      this.body = null
      this.searchClients = null
      this.isButtonSubmited = false;
      this.description = '';
      this.montageStart = null
      this.montageEnd = null
      this.pickUpDate = null
      this.priceWithoutVat = 0
      this.priceWithVAT = 0
      this.selectedVAT = 18
    },
  },
}
</script>

      <style scoped>
       .custom-modal__wrapper {
        border: 1px solid lightgray;
        border-radius: 10px;
        padding: 10px 10px;
      }
      .price-container {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
    }
      .validationClass{
        color: red;
        padding-top: 5px;
        margin-bottom: 0px;
      }
      .materialsClass{
        display:flex;
        flex-direction: column;
        gap:10px;
      }
      .allMaterialsDetails{
        display:flex;
        flex-direction: column;
        gap:15px;
        margin-top: 15px;
      }
      .allClases {
      display:flex;
      gap:15px;
      flex-wrap:wrap;
      }
      .form-radio{
        display:flex;
        align-items: center;
        gap:5px;
        width:100%;
      }
      .form-radio p {
        margin-bottom: 0px;
        font-size: 20px;
      }
      .orderOtherServiceClass{
        display:flex;
        gap:3px;
        width:100%
      }
      .nameFloor{
          height: 100%;
          display: flex;
          flex-direction: column;
      }

      .iconsFloor{
          width:100%;
          display:flex;
          justify-content: space-between;
      }

    .decorativeWindow{
      display:flex;
      flex-direction:column;
      gap:10px;
      border:1px solid lightgrey;
      border-radius:12px;
      padding:10px;
    }

      .sectionClass{
          border: 1px solid lightgrey;
          padding: 10px;
          border-radius: 12px;
      }

      .hrClass{
          color:gray;
      }

      .iconsClass{
          display:flex;
          gap:10px;
          align-items:center;
      }
    .ordersService{
      display:flex;
          gap:10px;
          align-items:center;
          width:100%
    }
      .homeEdit{
          display:flex;
          gap:5px;
          justify-content: space-between;
      }

      .homeName{
          display:flex;
          gap:5px;
          width:15%;
          align-items:center;
      }

      .floorWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    .objectsClass, .selectedFloor, .buttonNota {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: white;
      border-radius: 12px;
      padding: 15px;
      width: 140px;
      min-height: 140px;
      height: 100%;
    }

    .objectsClass {
      border: 1px solid lightgrey;
      color: black;
    }

    .selectedFloor {
      border: 2px solid red;
    }

      .collapse-button {
        padding: 0;
      }
      .houseDetail{
        display:flex;
        flex-direction: column;
        gap:10px;
      }
      .buttonsCollapsed{
        display:flex;
        justify-content:flex-end;
        align-items:flex-start;
      }
      .buttonAndCollapse{
        display:flex;
        justify-content: space-between
      }
      .buttonOnly{
        display: flex;
        justify-content: space-between
      }
      </style>
