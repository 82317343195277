import { render, staticRenderFns } from "./ArticleListing.vue?vue&type=template&id=c8716c76&scoped=true"
import script from "./ArticleListing.vue?vue&type=script&lang=js"
export * from "./ArticleListing.vue?vue&type=script&lang=js"
import style0 from "./ArticleListing.vue?vue&type=style&index=0&id=c8716c76&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8716c76",
  null
  
)

export default component.exports